$bg-color: #f5f5f5;
$text-color: #333333;

$white: #ffffff;
$black: #000000;

$primary: #6264a7;
$secondary: #6c757d;
$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;
$muted: #6c757d;
$info: #007bff;
$body: #333333;

$darkprimary: #3D3E66;
$lightprimary: #8285bb;
