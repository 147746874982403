@import "../../../../assets/styles/variable";

.lineTab .k-tabstrip-items-wrapper .k-item {
    color: #6c757d;
    font-size: 1rem;
    padding: 0;
    margin-right: 2rem;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 3px solid transparent !important;
}
.lineTab li.k-item.k-state-active {
    background: none;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 3px solid $primary !important;
    color: $primary;
}
.lineTab .k-tabstrip-items .k-link {
    padding: 6px 0 6px 0;
}
.lineTab .k-content {
    box-shadow: 0px 0.2rem 0.4rem -0.075rem rgba($black, 0.1);
}
.k-content .k-animation-container {
    width: 100%;
}
.bdrRow {
    border-top: 1px solid rgba($black, 0.08);
    display: flex;
    align-items: center;
    padding: 5px 0 5px 0;
}
.t-tile {
    border-bottom: 1px solid rgba($white, 0.3);
    padding: 0 0 5px 0;
    margin: 0 0 5px 0;
}
tr {
    &.p {
        &-credit,
        &-churned {
            > td {
                background-color: transparent;
            }
        }
        &-credit {
            &,
            &:hover,
            &.p-churned,
            &.p-churned:hover {
                background-color: rgba(255, 170, 68, 0.8) !important;
            }
        }
        &-churned {
            &,
            &:hover {
                background-color: rgba(196, 49, 75, 0.8) !important;
            }
        }
    }
}
// tr.p-credit {
//     background-color: rgba(255, 170, 68, 0.8);
//     &:hover{
//         background-color: rgba(255, 170, 68, 0.8) !important;
//     }
//     > td {
//         background: transparent;
//     }
// }

// tr.p-churned{
//     background-color: rgba(196, 49, 75, 0.8);
//     &:hover{
//         background-color: rgba(196, 49, 75, 0.8) !important;
//     }
//     > td {
//         background: transparent;
//     }
// }
.applied-sec {
    > .col {
        > .ui-flex {
            flex-wrap: wrap;
            > div {
                @media screen and (max-width: 1027px) {
                    width: 50%;
                    margin: 0 0 10px;
                }
                @media screen and (max-width: 600px) {
                    width: 100%;
                    margin: 0 0 10px !important;
                }
                .ui-dropdown__container {
                    width: 100%;
                    min-width: 0;
                    max-width: 100%;
                }
            }
        }
    }
    &.business {
        > .col {
            > .ui-flex {
                > div {
                    @media screen and (max-width: 950px) {
                        width: calc(33.33% - 5px);
                        margin: 0 2.5px 10px !important;
                    }
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        margin: 0 0 10px !important;
                    }
                }
            }
        }
    }
}

.intro-card {
    &-top {
        color: $white;
        padding: 15px 20px 60px;
        border-radius: 10px;
        line-height: 1.2;
        position: relative;
        .bg-icon {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 120px;
            line-height: 0.7;
            opacity: 0.1;
            span,
            svg {
                width: 100px;
                height: 100px;
            }
        }
    }
    &-bottom {
        padding: 0 20px 20px;
        margin-top: -50px;
        &-details {
            padding: 15px;
            background-color: $white;
            border-radius: 10px;
            box-shadow: rgba($black, 0.1) 0px 0.2rem 0.4rem -0.075rem;
        }
        .symbol {
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            border-radius: 10px;
            .symbol-label {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: #3f4254;
                background-color: #f5f8fa;
                border-radius: 10px;
            }
        }
        .icon {
            background-color: #fff8dd;
            padding: 10px;
            display: block;
            overflow: hidden;
            border-radius: 50%;
            svg {
                fill: #ffc700;
                // opacity: 0.5;
            }
        }
    }
}

.top-kpi {
    font-family: "Poppins", sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
    .ui-card {
        width: 100%;
        padding: 0;
        border-radius: 10px;
        &__header {
            padding-top: 6px;
            padding-bottom: 4px;
            padding-left: 16px;
            padding-right: 12px;
            background-color: $primary;
            border-radius: 6px 6px 0 0;
            h6 {
                color: rgba($white, 0.9) !important;
            }
            h1 {
                color: $white;
                .ui-pill {
                    color: $black;
                    font-size: 22px;
                    max-height: 20px;
                    background-color: $white;
                    top: -7px;
                    &.healthy {
                        color: #009b23;
                    }
                    &.critical {
                        color: $danger;
                    }
                    .ui-pillcontent {
                        padding: 5px 4px;
                        padding-bottom: 3px;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    .ui-icon {
                        width: 9px;
                        top: -3px;
                    }
                }
            }
        }
        &__body {
            border-radius: 0 0 10px 10px;
        }
        h1 {
            font-size: 28px;
            font-weight: 300;
            line-height: 28px;
        }
        h2 {
            font-size: 22px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 15px;
        }
        h5 {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        h6 {
            color: #888888;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 400;

            span {
                text-transform: none;
                font-size: 10px;
            }
        }
    }
    .ui-pill {
        color: #fff;
        font-size: 16px;
        min-width: 0;
        min-height: 0;
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 5px;
        max-height: 20px;
        position: relative;
        top: -4px;
        &.healthy {
            background-color: rgba($success, 0.3);
            color: #0a531b;
        }
        &.critical {
            background-color: rgba($danger, 0.3);
            color: $danger;
        }
        .ui-pillcontent {
            padding: 5px 4px;
            padding-bottom: 3px;
        }
        .ui-icon {
            width: 12px;
            position: relative;
            top: -3px;
            svg {
                height: 100%;
                width: 100%;
            }
        }
    }
    .border-bottom {
        border-bottom: 1px solid #bfbfbf;
    }
    .border-right {
        // border-right: 1px solid #bfbfbf;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: calc(100% - 15px);
            right: 0;
            top: 7.5px;
            background-color: #bfbfbf;
        }
    }

    // .rr-top-heading {
    //     min-height: 42px;
    // }

    .rr-top-heading span {
        font-size: 10px;
    }
}

.chart-popup {
    color: #434386;
    min-width: 210px;
    min-height: 100px;
    padding: 8px;
    border: 1px solid #dadce5;
    // background: aliceblue;
    position: absolute;
    // left: 0;
    // top: 0;
    z-index: 1;
    border-radius: 4px;
    background-color: #dde4eb;

    .chart-detail-close {
        min-width: 2px;
        height: 14px;
        position: absolute;
        top: 2px;
        right: 2px;
    }
}

.bussiness-insights-container {
    padding: 8px;
    display: grid;
    overflow-y: auto;
    grid-gap: 17px;
    .bussiness-insight {
        padding: 13px 8px 8px 8px !important;
        position: relative;
        .creation-details {
            position: absolute;
            right: 8px;
            top: -12px;
            background-color: #ccc;
            padding: 0 8px 2px 8px;
            border-radius: 12px;
        }
    }
}

.part-billing-checkbox {
    border: 1px solid rgba(0, 0, 0, .08);
    /* border-color: rgb(71 71 71); */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0.02rem 0.15rem 0.04rem; */
    background-color: rgb(255, 255, 255);
    margin: 0 0 0 8px;
    height: 32px;
    align-items: center !important;
    /* border-width: 1px; */
    border-radius: 4px;
    
    // border-color: rgb(225, 223, 221);
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem;
    // background-color: rgb(255, 255, 255);
    // margin: 0 0 0 8px;
    // height: 32px;
    // align-items: center !important;
    // border-width: 1px;
    // border-radius: 4px;
}
.graph-header {
    padding-right: 60px;
}