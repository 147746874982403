@import "../../../assets/styles/variable";

.settings {
    height: calc(100vh - 38px - 1rem);

    .ui-alert {
        min-height: 0;
    }

    .menu-icon {
        position: absolute;
        left: 100%;
        top: 50px;
        display: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover {
            background-color: $primary;
            color: $white;
        }

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    .left-segment {
        .categorylist {
            // height: calc(100% - 24px);
            overflow: auto;
        }

        >div {
            height: 100%;
            overflow-y: auto;
        }

        transition: all 0.5s;

        @media screen and (max-width: 768px) {
            position: fixed;
            top: 0;
            left: -250px;
            z-index: 99;
            width: 250px;
        }

        &.active {
            @media screen and (max-width: 768px) {
                left: 0;
            }
        }
    }

    .right-segment {
        overflow: hidden;

        .ui-card__body {
            height: 100%;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .cal-cb {
        height: calc(100% - 40px) !important;
        display: block !important;
    }
}

.k-window {
    &-titlebar {
        color: #fff !important;
        background: #6264a7 !important;
    }
}

.k-dialog {
    &-titlebar {
        color: #fff !important;
        background: #6264a7 !important;
    }
}

.k-header {
    color: #fff !important;
    background: #6264a7 !important;
}

.grid-with-search {
    height: calc(100% - 84px);
}

[data-aa-class="Popup__popup"],
.ui-popup__content {
    z-index: 10003 !important;
}

.ui-dropdown__items-list {
    max-height: 180px !important;
}

.current-members>.ui-segment {
    &.Primary {
        color: #fff;
        background: rgb(91, 95, 199);

        .ui-avatar>.ui-label {
            color: rgb(91, 95, 199);
            font-weight: 600;
        }
    }

    &.Supervisor {
        color: #fff;
        background: rgb(35, 123, 75);

        .ui-avatar>.ui-label {
            color: rgb(35, 123, 75);
            font-weight: 600;
        }
    }

    &.Secondary {
        min-width: 250px;
        min-height: 150px;
        max-width: 250px;
    }
}

.planned-members>.ui-segment {
    height: 130px !important;
    width: calc(50% - 8px) !important;
    padding: 5px 8px;
    margin: 0 8px 8px 0;

    &.Primary {
        border-color: #5b5fc7;

        .ui-avatar>.ui-label {
            color: #fff;
            background: #5b5fc7;
        }
    }

    .avt-details {
        max-width: calc(100% - 50px);

        .uiflex {
            max-width: 100%;
        }

        .ui-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.oncall-calender {
    width: 100% !important;
    height: calc(100% - 10px) !important;

    >.k-calendar-view {
        width: calc(100% - 50px) !important;
        height: 100%;
        font-size: 16px;

        .k-calendar-tbody[role="rowgroup"] {
            tr.k-calendar-tr[role="row"] {
                height: 60px !important;
            }
        }
    }

    .k-nav-today {
        display: none !important;
    }

    .ui-pill {
        max-width: 100%;
        margin: 2px 0;

        .ui-pillcontent {
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &.Primary {
            background: #5b5fc7 !important;
            color: #fff !important;
        }
    }
}

.supervisor-dropdown>.ui-dropdown__container {
    width: 200px;
}

.calender-view {
    height: calc(100% - 214px) !important;
}

.k-dialog-md>.k-dialog {
    min-width: 400px !important;
}

.email-log-filter {
    display: none !important;
    position: absolute;
    flex-direction: column;
    top: calc(100% + 5px);
    background-color: $white;
    z-index: 9;
    padding: 20px;
    box-shadow: 0 0 10px rgba($black, 0.2);
    border-radius: 10px;
    right: 0;

    @media screen and (max-width: 500px) {
        padding: 10px;
    }

    .date-filter-text {
        align-self: start;
    }

    .date-filter {
        margin-bottom: 15px;

        input {
            padding-right: 0 !important;
        }
    }

    &.active {
        display: flex !important;
    }
}

.checknow {
    .ui-dropdown__selected-items {
        &:has(input:disabled) {
            position: relative;

            &:after {
                height: 100%;
                width: 100%;
                content: "";
                position: absolute;

            }
        }
    }
}

.react-flow__node p {
    margin: 3px;
}

.k-dialog-wrapper.k-dialog-infraDoc>.k-dialog {
    width: 80%;
    height: 80%;
}

.react-flow__background {
    display: none;
}

.heading-text {
    .text-bg {
        margin: 0;
        display: inline-block;
        padding: 0 10px;
        background-color: #fff;
        position: relative;
        top: -11px;

        >span {
            position: relative;
            padding: 0 15px;
            margin-right: 5px;

            &:after,
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                font-size: 22px;
            }

            &:after {
                content: '>';
                right: 0;
            }

            &:before {
                content: '<';
                left: 0;
            }
        }
    }
}

.custom-node {
    display: grid;
    justify-items: center;
    text-align: center;

    >div {
        min-width: 130px !important;
    }
}

.react-flow__handle {
    background: transparent !important;
}

.react-flow__resize-control {
    .handle {
        height: 15px !important;
        width: 15px !important;
    }

    .line {
        height: 15px !important;
    }
}


//remove checkbox top margin for alignment
.checkBoxMT-0 {
    .ui-checkbox__indicator {
        margin-top: 0 !important;
    }
}