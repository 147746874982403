@import "../../../assets/styles/variable";

.dashboard {
    width: 100%;
    height: 100%;

    &-container {
        height: 100%;
    }

    &-body {
        height: calc(100% - 40px);
    }

    &-top {
        .card {
            &.selected {
                border-bottom-color: #5b5fc7;
                border-bottom-width: 4px;
            }

            width: 100%;
            overflow: hidden;
            padding: 10px 10px;
            border-radius: 10px;

            @media screen and (max-width: 840px) {
                padding: 10px;
            }

            .heading {
                margin-right: auto;

                .ui-text {
                    font-size: 20px;
                    font-weight: 600;

                    @media screen and (max-width: 1300px) {
                        font-size: 18px;
                    }

                    @media screen and (max-width: 1150px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: 1023px) {
                        font-size: 16px;
                    }
                }
            }

            .ui-card__body {
                position: relative;
                margin-bottom: 0;

                .ui-icon {
                    position: absolute;
                    opacity: 0.08;
                    left: 0;
                    top: -15px;
                    z-index: 0;

                    svg {
                        height: 60px;
                        width: 60px;
                    }
                }

                .card-actions {
                    margin-left: 10px;

                    @media screen and (max-width: 1300px) {
                        margin-left: 0px;
                    }

                    .ui-icon {
                        position: relative;
                        top: auto;
                        opacity: 1;

                        svg {
                            height: 22px;
                            width: 22px;

                            @media screen and (max-width: 840px) {
                                height: 16px;
                                width: 16px;
                            }
                        }
                    }
                }
            }

            &.card-infra {
                background-color: #fff8dd;

                .heading {
                    .ui-text {
                        color: #ffc700;
                    }
                }

                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #ffc700;
                        }
                    }
                }
            }

            &.card-portal {
                background-color: #f1faff;

                .heading {
                    .ui-text {
                        color: #009ef7;
                    }
                }

                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #009ef7;
                        }
                    }
                }
            }

            &.card-service {
                background-color: #fff5f8;

                .heading {
                    .ui-text {
                        color: #f1416c;
                    }
                }

                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #f1416c;
                        }
                    }
                }
            }
        }

        .ui-grid {
            @media screen and (max-width: 768px) {
                display: block;
            }

            .card {
                @media screen and (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}


// Start of priorities

// bg animation
.test {
    width: 100%;
    height: 100%;
    color: #fff;
    /* backdrop-filter: blur(10px); */
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
}

.paused {
    -webkit-animation-play-state: paused;
    /* Safari 4.0 - 8.0 */
    animation-play-state: paused;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

.bpShadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: .5rem;
    height: 100% !important;
}

.btn-npx {
    min-width: fit-content;
    width: auto;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    color: white !important;
    font-weight: 600 !important;
    padding: 0 1rem 0 0 !important;
    border-radius: 0;

    &:hover {
        color: azure;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    }
}

.btn-icon {
    font-size: 3rem !important;
}

div[role="radiogroup"].rdCompare{
    width: 100%;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: calc(50% - 50px) calc(50% - 50px);
    > div[role="radio"].ui-radiogroup__item{
        width: 100%;
        height: 100px;
        background-color: rgb(255, 255, 255);
        border-color: transparent;
        border-top-width: 2px;
        border-radius: 0;
        color: rgb(37, 36, 35);
        box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 1px 1px;

        &[aria-checked="true"]{
            border-color: #6264a7 !important;
            border-width: 2px;
        }

        > div.ui-radiogroup__item__indicator{
            display: none;
        }

        &:last-child::before {
            content: 'OR';
            font-size: 24px;
            position: absolute;
            left: -70px;
        }
    }
}

.k-panelbar.prioritylevels{
    .k-panelbar-item-text{
        font-size: 19px;
        width: calc(100% - 35px);
        .ui-icon{
            padding: 8px;
        }
    }

    >.k-panelbar-header{
        &.high{
            > span.k-link{
                border-color: #8E192E;
                background-color: #8E192E;
                color: #FFF;
            }
        }
        &.medium{
            > span.k-link{
                border-color: #FFAA44;
                background-color: #FFAA44;
                color: #FFF;
            }
        }
        &.low{
            > span.k-link{
                border-color: #237B4B;
                background-color: #237B4B;
                color: #FFF;
            }
        }
    }
}

.businesspriorities{
    .ui-card{
        &.HIGH{
            color: #8E192E;
        }
        &.MEDIUM{
            color: #FFAA44;
        }
        &.LOW{
            color: #237B4B;
        }
    }
}

.comp-progress{
    width: 100%;
    height: 3px;
    margin-bottom: 8px !important;
}
.priority-input{
    height: 60px!important;
    padding: 20px!important;
    font-size: 16px;
}
.business-desc{
    height: 60px;
    padding-left: 42px;
    padding-right: 62px;
    *:not(label) {
        height: 100%;
    }
}