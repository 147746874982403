@import "../../../../assets/styles/variable";

.ui-card.card-action.selected {
    border-bottom: 3px solid #5b5fc7;
}

.state-pill.ui-pill {
    color: #FFF;
    min-width: 0;

    &.healthy {
        background-color: $success;
    }

    &.warning {
        background-color: $warning;
    }

    &.critical {
        background-color: $danger;
    }
}

span.ui-pillcontent {
    font-size: 0.68rem;
}