@import "../../../assets/styles/variable";


.custom-dropdown .menu {
    max-height: none !important;
    /* Disable any maximum height */
    overflow: visible !important;
    /* Ensure no scrollbar */
    height: auto !important;
    /* Allow the height to adjust automatically */
}

/* Additional styling for the items */
.custom-dropdown .menu>.item {
    padding: 8px 12px !important;
    /* Adjust padding to prevent clipping */
}

/* In some cases, you might need to set a height large enough for all 6 items */
.custom-dropdown .menu {
    height: 240px !important;
    /* Set a height large enough to fit all items */
}

.dashboard {
    width: 100%;
    height: 100%;
    &-container {
        height: 100%;
        .dashboard-header {
            > .ui-flex {
                @media screen and (max-width: 550px) {
                    flex-direction: column;
                }
            }
        }
    }
    &-body {
        height: calc(100% - 40px);
    }
    &-top {
        .card {
            &.selected {
                border-bottom-color: #5b5fc7;
                border-bottom-width: 4px;
            }
            width: 100%;
            overflow: hidden;
            padding: 10px 16px;
            @media screen and (max-width: 840px) {
                padding: 10px;
            }
            .heading {
                margin-right: auto;
                .ui-text {
                    font-size: 20px;
                    font-weight: 600;
                    @media screen and (max-width: 1300px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 1150px) {
                        font-size: 14px;
                    }
                    @media screen and (max-width: 1023px) {
                        font-size: 16px;
                    }
                }
            }
           .subhead{
             margin-bottom: 10px;
             }
            .ui-card__body {
                position: relative;
                margin-bottom: 0;
                .ui-icon {
                    position: absolute;
                    opacity: 0.08;
                    left: 0;
                    top: -15px;
                    z-index: 0;
                    svg {
                        height: 60px;
                        width: 60px;
                    }
                }

                .card-actions {
                    margin-left: 10px;
                    @media screen and (max-width: 1300px) {
                        margin-left: 0px;
                    }
                    .ui-icon {
                        position: relative;
                        top: auto;
                        opacity: 1;
                        svg {
                            height: 22px;
                            width: 22px;
                            @media screen and (max-width: 840px) {
                                height: 16px;
                                width: 16px;
                            }
                        }
                    }
                }
            }
            &.card-infra {
                background-color: #fff8dd;
                .heading {
                    .ui-text {
                        color: #ffc700;
                    }
                }
                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #ffc700;
                        }
                    }
                }
            }
            &.card-portal {
                background-color: #f1faff;
                .heading {
                    .ui-text {
                        color: #009ef7;
                    }
                }
                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #009ef7;
                        }
                    }
                }
            }
            &.card-service {
                background-color: #fff5f8;
                .heading {
                    .ui-text {
                        color: #f1416c;
                    }
                }
                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #f1416c;
                        }
                    }
                }
            }
            &.card-dbm {
                background-color: #f1edff;
                .heading {
                    .ui-text {
                        color: #7961cd;
                    }
                }
                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #7961cd;
                        }
                    }
                }
            }
            &.card-provi {
                background-color: #edfff7;
                .heading {
                    .ui-text {
                        color: #2cc07e;
                    }
                }
                .ui-card__body {
                    .ui-icon {
                        svg {
                            fill: #2cc07e;
                        }
                    }
                }
            }
        }
        .ui-grid {
            @media screen and (max-width: 768px) {
                display: block;
            }
            .card {
                @media screen and (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.card-actions {
    .ui-button {
        &.selected {
            color: rgb(91, 95, 199);
            transform: scale(1.2);
            svg {
                fill: #5b5fc7 !important;
                .ui-icon__outline path {
                    fill: $white;
                }
                .ui-icon__filled {
                    display: block;
                }
            }
        }
    }
}

.btn {
    &.multi {
        .ui-button {
            color: unset;
            .ui-box {
                background-color: transparent;
                @media screen and (max-width: 1300px) {
                    margin-left: 7px;
                }
                .incident-counts {
                    margin-top: -2px;
                    > span {
                        font-weight: 600;
                        padding: 3px 6px;
                        display: flex;
                        width: auto;
                        border-radius: 2px;
                        color: #ffffff;
                        font-size: 13px;
                        margin-left: 3px;
                        margin-top: -2px;
                        width: 25px;
                        flex-direction: column;
                        height: 22px;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: 1300px) {
                            margin-left: 2px;
                        }
                        &.critical {
                            background-color: $danger;
                            border-color: $danger;
                        }
                        &.warning {
                            background-color: $warning;
                            border-color: $warning;
                        }
                    }
                }
            }
            &.selected {
                background-color: #5b5fc7;
                color: $white;
                border-color: #5b5fc7;
            }
        }
    }
    .ui-button {
        &:hover {
            .ui-button__content {
                color: rgb(72, 77, 68) !important;
            }
        }
        padding: 0 5px;
        min-width: 0;
        border-width: 0 0 0 4px;
        border-radius: 0;
        color: $white;
        max-width: 100%;
        .ui-button__content {
            font-weight: 400;
            font-size: 13px;
            @media screen and (max-width: 840px) {
                font-size: 12px;
            }
            white-space: break-spaces;
        }
        .ui-box {
            margin-left: auto;
            font-weight: 600;
            background-color: $white;
            padding: 0 5px;
            display: inline-block;
            width: auto;
            border-radius: 2px;
            color: #555;
            font-size: 13px;
            margin-left: 15px;
            @media screen and (max-width: 840px) {
                font-size: 12px;
                margin-left: 5px;
                line-height: 15px;
            }
        }
        &.selected {
            .ui-button__content {
                font-weight: 600;
            }
        }
    }
    &.up {
        .ui-button {
            background-color: $success;
            border-color: $success;
        }
        &.selected {
            .ui-button {
                border-color: darken($success, 15%);
            }
        }
    }
    &.down {
        .ui-button {
            background-color: $danger;
            border-color: $danger;
        }
        &.selected {
            .ui-button {
                border-color: darken($danger, 15%);
            }
        }
    }
    &.critical {
        .ui-button {
            background-color: $danger;
            border-color: $danger;
        }
        &.selected {
            .ui-button {
                border-color: darken($danger, 15%);
            }
        }
    }
    &.warning {
        .ui-button {
            background-color: $warning;
            border-color: $warning;
        }
        &.selected {
            .ui-button {
                border-color: darken($warning, 15%);
            }
        }
    }
    &.healthy {
        .ui-button {
            background-color: $success;
            border-color: $success;
        }
        &.selected {
            .ui-button {
                border-color: darken($success, 15%);
            }
        }
    }
}

.ui-toolbar {
    > .heading {
        margin-right: auto;
    }

    .ui-toolbar__customitem {
        &.btn {
            .ui-button {
                &:hover {
                    color: $white !important;
                }
            }
            .selected {
                color: $white !important;

                .ui-box {
                    background-color: #5b5fc7;
                    color: $white !important;
                }
            }
        }
    }
}

.ui-dropdown__item {
    &._storageAccount::after {
        content: "(Storage Account)";
        font-size: 0.7rem;
    }
    &._virtualMachine::after {
        content: "(Virtual Machine)";
        font-size: 0.7rem;
    }
}

.width-limit-ddl {
    > .ui-dropdown {
        max-width: 100px;
        > .ui-dropdown__container {
            max-width: 100%;
            > .ui-dropdown__items-list {
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .dashboard-top {
        .ui-grid {
            .ui-card__body {
                .ui-toolbar {
                    flex-wrap: wrap;
                    .heading {
                        min-width: 100%;
                        -webkit-box-pack: start;
                        justify-content: start;
                    }
                }
            }
        }
    }
}

.overview-status {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(4, 1fr);
}

// .k-grid-header th:nth-of-type(1) {
//     min-width: 100px;
// }
// .k-grid-table td:nth-of-type(1) {
//     min-width: 100px;
// }
// .k-grid-header th:nth-of-type(2) {
//     min-width: 100px;
// }
// .k-grid-table td:nth-of-type(2) {
//     min-width: 100px;
// }
// .k-grid-header th {
//     min-width: 100px;
// }
// .k-grid-table td {
//     min-width: 100px;
// }
.anch-rdp {
    &.disabled {
        color: #CCC;
    }
}
.detailed-cards {
    .col-lg-4 {
        @media screen and (max-width: 1023px) {
            margin-bottom: 15px;
        }
    }
}
.header-flex {
    align-items: center !important;
    @media screen and (max-width: 550px) {
        align-items: start !important;
        margin-bottom: 10px;
    }
}
.dashboard-selectbox .ui-dropdown__container {
    @media screen and (max-width: 1023px) {
        width: 180px;
        margin-top: 10px;;
    }
}

.k-chart.k-widget.new-billing-chart > div.k-chart-surface{
    height: 400px;
    > svg > g{
        height: 400px;
    }
}

.ddl-container>div {
    margin-left: 15px;
}

.multi .ui-button{
    border-radius: 4px;
}