/* General Utility Styles */
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
input[type="text"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    font-size: 14px;
}

.unhealthy {
    background-color: #ffcccb;
    /* Light red background */
    color: #ff0000;
    /* Red text */
}

.tooltip-content {
    /* Customize the tooltip styles */
    background-color: #f1f1f1;
    /* Light background */
    color: #333;
    /* Dark text for better contrast */
    border: 1px solid #ddd;
    /* Subtle border */
    border-radius: 4px;
    /* Rounded corners */
    padding: 8px;
    /* Padding for aesthetics */
    transition: all 0.2s ease;
    /* Smooth transition */
}

.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-width {
    width: 100%;
}

.auto-overflow {
    overflow: auto;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-row {
    display: flex;
    align-items: center;
}

.align-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.text-white {
    color: white;
}

.text-center {
    text-align: center;
}

/* Backdrop Styles */
.popup-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* General Popup Styles */
.my-popup,
.filter-dialog,
.popup-message {
    position: fixed;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.date-picker-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.my-popup.style {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(113px, 148px);
}

.date-picker-field input {
    flex: 1;
    min-width: 120px;
    max-width: 180px;
}

/* Filter Dialog */
.filter-dialog {
    width: 400px;
    max-height: 600px;
    overflow-y: auto;
}

.filter-dialog .flex-row>* {
    flex: 1;
    margin-right: 8px;
}

.filter-dialog .apply-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.filter-dialog .apply-buttons>button {
    margin-left: 10px;
}

.filter-dialog-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Filter Styles */
.filter-container,
.filter-pill-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.filter-pill {
    padding: 5px 10px;
    margin: 4px;
    border-radius: 16px;
    background-color: #0078d4;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.filter-pill button {
    background: none;
    border: none;
    color: white;
    margin-left: 5px;
    cursor: pointer;
}

.filter-pill.active {
    background-color: #0078d4;
    border-color: #0078d4;
}

.filter-group {
    margin-right: 20px;
}

/* Buttons */
button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.open-filter-btn {
    margin: 20px;
    background-color: #007bff;
    color: white;
}

.open-filter-btn:hover {
    background-color: #0056b3;
}

.add-filter-btn {
    background-color: #0078d4;
    color: white;
    padding: 5px 10px;
    margin-left: 10px;
}

.apply-filter-btn {
    background-color: #28a745;
    color: white;
}

.apply-filter-btn:hover {
    background-color: #218838;
}

.close-filter-btn {
    background-color: #dc3545;
    color: white;
}

.close-filter-btn:hover {
    background-color: #c82333;
}

/* Kendo Grid Styles */
.k-grid td {
    white-space: nowrap;
}

.k-grid-header .k-header {
    white-space: normal;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

.k-grid .k-grid-header th {
    height: auto;
    vertical-align: middle;
}

/* Card Styles */
.card-border {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: #3f51b5;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
}

.card-body {
    padding: 15px;
}

/* Specific Styles */
.rev-chart {
    padding: 20px;
    text-align: center;
}

.healthy {
    background-color: #4caf50;
    color: white;
}

/* Date Picker */
.date-picker {
    min-width: 100px;
}

/* Filter Header */
.filter-header h4 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.filter-body {
    margin-top: 10px;
}

.filter-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
