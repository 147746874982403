.error {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .notfound {
        position: relative;
    }
}
.notfound {
    // max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    &-section {
        position: relative;
        display: grid;
        margin: 0px auto 80px;
        h1, h2 {
            font-family: "Montserrat", sans-serif;
            margin: 0px;
            text-transform: uppercase;
            color: $text-color;
            display: inline-block;
        }
        h1 {
            font-size: 8rem;
            font-weight: 200;
        }
        h2 {
            font-size: 16px;
            font-weight: 400;
            background: $bg-color;
            padding: 10px 5px;
            margin: auto;
        }
        &.welcome {
            h1 {
                font-size: 150px;
                @media screen and (max-width: 920px) {
                    font-size: 100px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 50px;
                }
            }
        }
    }
}