* {

    &,
    &::after,
    &::before {
        box-sizing: border-box;
    }
}

html,
body {
    color: $text-color;
    background-color: $bg-color;
    height: 100%;
    font-family: "Poppins", sans-serif !important;
}

#root .main-container {
    height: 100vh;

    >.ui-flex {
        height: 100%;
    }
}

.container {
    width: 100%;
    padding: 0 15px;
    height: calc(100% - 50px);
    overflow-y: auto;
}

// .card-border {
//     border-top: 1px solid rgba($black, 0.05) !important;
// }
.card-hov:hover {
    background: $white !important;
}

.stausDot {
    border-radius: 100%;
    width: 9px;
    height: 9px;
}

.staus-bookmark {
    top: 0;
    left: 0;
    position: absolute;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid $white;
    background-color: transparent !important;

    &.bg-success {
        border-top-color: $success;
    }

    &.bg-warning {
        border-top-color: $warning;
    }

    &.bg-danger {
        border-top-color: $danger;
    }
}

.align-item-center {
    align-items: center;
}

.listV {
    // border-top: 1px solid rgba($black, 0.05);
    padding: 8px 0 4px 0;
    margin: 8px 0 4px 0;
    display: grid;
    grid-template-columns: 45% 10% 45%;
    align-items: center;

    @media screen and (min-width: 769px) and (max-width: 1023px) {
        display: block;
    }

    .listVtrk {
        @media screen and (min-width: 769px) and (max-width: 1023px) {
            min-width: 100% !important;
        }

        &:nth-child(2) {
            @media screen and (min-width: 769px) and (max-width: 1023px) {
                display: none;
            }
        }
    }

    .listV>.listVtrk:nth-child(2) {
        text-align: center;
    }
}

.border-gray {
    border: 1px solid rgba($black, 0.05);
}

.border-radius-6 {
    border-radius: 6px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.br-0 {
    border-radius: 0 !important;
}

.card-action,
.cursor-pointer {
    cursor: pointer;
}

.ui-datepicker__calendar {
    max-width: 238px !important;
    max-height: 282px !important;
}

.ui-datepicker {
    &.fluid {
        >div.ui-box {
            width: calc(100% - 32px);

            input.ui-input__input {
                width: 100%;
            }
        }
    }
}

.datePickerContainer {
    width: 100%;
    position: relative;

    .datePicker {
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;

        input {
            width: 100%;
        }
    }

    .datePickerLabel {
        width: 100%;
        display: inline-block;
        padding: 0.3571rem 0 0.3571rem 0.8571rem;
        margin-bottom: 0;
        position: relative;
        // border-bottom-style: solid;
        // border-bottom-width: 0.125rem;
        border-radius: 0.1875rem 0.1875rem 0.125rem 0.125rem;
        line-height: 1.85;

        &.disabled {
            opacity: 0.4;
        }

        .ui-icon {
            position: absolute;
            top: calc(50% - 20px);
            right: 0;
            border: 1px solid rgb(225, 223, 221);
            padding: 9px;
            background: $white;

            >svg {
                height: 18px;
                width: 18px;
            }

            &:hover {
                >svg {
                    fill: rgb(91, 95, 199);
                }
            }
        }
    }
}

body.dark {
    .datePickerContainer {
        .datePickerLabel {
            // border-bottom-color: rgb(166, 167, 220);
            background-color: #333;

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(166, 167, 220);
                    }
                }
            }
        }
    }

    .timePickerContainer {
        .timePickerLabel {
            // border-bottom-color: rgb(166, 167, 220);
            background-color: #333;

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(166, 167, 220);
                    }
                }
            }
        }
    }
}

body.contrast {
    .datePickerContainer {
        .datePickerLabel {
            border: 0.0625rem solid rgba($white, 1);
            background-color: rgba($black, 1);

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(26, 235, 255);
                    }
                }
            }
        }
    }

    .timePickerContainer {
        .timePickerLabel {
            border: 0.0625rem solid rgba($white, 1);
            background-color: rgba($black, 1);

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(26, 235, 255);
                    }
                }
            }
        }
    }
}

body.default {
    .datePickerContainer {
        .datePickerLabel {
            // border-bottom-color: rgb(98, 100, 167) ;
            background-color: #ebebeb;

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(98, 100, 167);
                    }
                }
            }
        }
    }

    .timePickerContainer {
        .timePickerLabel {
            // border-bottom-color: rgb(98, 100, 167) ;
            background-color: #ebebeb;

            .ui-icon {
                >svg {
                    &:hover {
                        fill: rgb(98, 100, 167);
                    }
                }
            }
        }
    }
}

.k-grid {
    tr {
        td {
            td {
                border: 0 !important;
                padding: 0;
            }
        }
    }
}

.timePickerContainer {
    width: 100%;
    position: relative;

    .timePicker {
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;

        input {
            // padding: 0.3571rem 0.8571rem;
            height: 34px;
            position: relative;

            &::-webkit-calendar-picker-indicator {
                font-size: 21px !important;
                position: absolute;
                right: 0.8571rem;
                top: calc(50% - 12px);
            }
        }

        .ui-input__icon {
            right: calc(0.8571rem + 30px);
        }
    }

    .timePickerLabel {
        min-height: 34px;
        width: 100%;
        padding: 0.3571rem 0.8571rem;
        margin-bottom: 0;
        position: relative;
        // border-bottom-style: solid;
        // border-bottom-width: 0.125rem;
        border-radius: 0.1875rem 0.1875rem 0.125rem 0.125rem;
        line-height: 1.85;

        .ui-icon {
            position: absolute;
            right: calc(0.8571rem + 4px);
            top: calc(50% - 9px);

            >svg {
                height: 18px;
                width: 18px;
            }

            &.validIndicator {
                right: calc(0.8571rem + 30px);
                top: calc(50% - 0.5rem);

                >svg {
                    height: 1rem;
                    width: 1rem;
                    fill: rgb(35, 123, 75) !important;
                }
            }
        }
    }
}

.k-window {
    max-height: 90%;
    max-width: 90%;

    &-content {
        height: calc(100% - 45px);

        .ui-form {
            justify-content: initial;
        }
    }
}

#sp-access .sp-access-button {
    min-width: 0;
    margin-right: 2px;
}

.add-comment-button {
    min-width: 100px !important;
}

.card-header-resellers {
    position: relative;
}

.card-header-resellers .alert-resellers {
    position: absolute;
    top: 40px;
}

.overview-status button {
    justify-content: flex-start;

    //min-width: 32px;
    div {
        justify-content: flex-start;
        min-width: none;
    }

    span {
        font-weight: bold;
    }
}

.overview-incident-counts {
    border: 1px solid #e5e5e5;
    margin-right: 25px;
    box-shadow: rgba($black, 0.1) 0px 0.2rem 0.4rem -0.075rem;
    padding: 15px 15px !important;
    font-weight: 600;
    font-size: 0.875rem !important;
    color: rgba(45, 45, 45, 1) !important;
}

.overview-incident-counts .incident-counts>span {
    font-weight: 600;
    padding: 1px 6px;
    display: inline-block;
    width: auto;
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    margin-left: 15px;
    line-height: 1.15;
}

.overview-incident-counts .incident-counts>span.critical {
    background-color: #dc3545;
    border-color: #dc3545;
}

.overview-incident-counts .incident-counts>span.warning {
    background-color: #ffc107;
    border-color: #ffc107;
}

.incident-count {
    >span {
        font-weight: 600;
        padding: 1px 6px;
        display: inline-block;
        width: auto;
        border-radius: 2px;
        color: $white;
        font-size: 13px;
        margin-left: 15px;
        line-height: 1.15;

        &.critical {
            background-color: $danger;
            border-color: $danger;
        }

        &.warning {
            background-color: $warning;
            border-color: $warning;
        }
    }
}

.k-dialog-wrapper {
    &.wide {
        .k-dialog {
            min-width: 70%;
            max-width: 90%;
        }
    }

    &.mid-wide {
        .k-dialog {
            min-width: 60%;
            max-width: 60%;
        }

        &.restricted-width {
            .k-dialog {
                min-width: 40%;
                max-width: 60%;
            }
        }
    }
}

.hover-list {
    >li[role="listitem"] {
        padding-top: 8px;
        padding-bottom: 8px;

        &:hover {
            background: rgb(245, 245, 245);
        }
    }
}

.line-clamp-2 {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.permissions-dailog {
    justify-content: flex-start;
    margin-top: 50px;
}

.permissions-title {
    margin-bottom: 10px !important;
}

.permissions-grid label {
    margin-top: 3px;
}

.permissions-grid .permissions-grid-title {
    margin-bottom: 4px;
}

.permissions-grid .permissions-grid-title .ui-text {
    font-size: 16px;
}

.permissions-grid .permissions-grid-title-settings {
    margin-top: 10px;
}

.permissions-grid {
    margin-left: 0;
    margin-right: 0;
}

.d-none {
    display: none;
}

.settings-tooltip .k-tooltip {
    border-color: rgba(0, 0, 0, 0.75);
    color: black;
    background-color: rgba(222, 222, 222, 0.9);
    font-size: 12px;
}

.button-container .settings-button {
    max-width: 100px;
}

.button-container {
    justify-content: flex-end;
}

.permission-checkbox span {
    margin-top: 2px;
}

.permissions-pill .ui-pill {
    margin-top: 2px;
    margin-bottom: 2px;
    height: 1.3rem;
    max-height: 1.3rem;
}

.permissions-pill .ui-pillcontent {
    padding-top: 2px;
    padding-bottom: 2px;
}

.k-grid button.tenantStatus {
    height: 1.6rem;
    // word-break: normal;
}

.k-grid tr td.tenantStatus {
    word-break: normal;
}

// .k-grid tr td {
//     // text-align: center;
//     // word-break: break-word;
// }

.overview-minutes {
    margin-top: -8px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.dayNight-icon {
    width: 14px;
    vertical-align: -2px;
    margin-left: 5px;
}

.position-relative {
    position: relative !important;
}

.inline-grid {
    /* border: 1px solid rgb(155, 155, 155); */
    border-collapse: collapse;
    // width: 60%;
}

.inline-grid td,
.inline-grid th {
    border: 1px solid rgb(131, 131, 131);
    padding: 2px 10px;
    /* min-width: 150px; */
    word-break: break-all;
}

.inline-grid button {
    cursor: pointer;
    width: 80px;
    margin-right: 4px;
    margin-left: 4px;
    color: #0060a1;
    font-size: 12px;
    font-weight: bold;
}

.inline-grid input {
    max-width: 120px;
    font-family: inherit;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.inline-grid thead {
    font-size: 12px;
}

.inline-grid tbody {
    font-size: 14px;
}

.grid-inline-save,
.grid-inline-add {
    cursor: pointer;
    width: 80px;
    /* margin-right: 8px; */
    color: #0060a1;
    // font-size: 12px;
    font-weight: bold;
    /* margin-bottom: 13px; */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
}

.grid-inline-save {
    float: right;
}


.inline-grid .ui-dropdown {
    width: 150px;
}

.inline-grid .ui-dropdown__items-list {
    width: 150px;
}

.inline-grid .ui-dropdown__trigger-button {
    height: 20px;
    min-height: 20px;
}

.ui-dialog {
    padding: 0 !important;
    border-radius: 0 !important;
    margin: 0 auto;

    @media screen and (max-width: 1023px) {
        width: 95% !important;
        max-width: 95% !important;
    }

    .ui-header {
        background-color: $primary;
        display: block;
        width: 100%;
        color: #fff;
        font-weight: normal;
        font-size: 16px;
        padding: 12px 16px;
        margin: 0;
    }

    .ui-dialog__headerAction {
        position: absolute;
        z-index: 999999;
        right: 0;
        top: 7px;
        margin: 0;

        svg {
            fill: #fff;
        }
    }

    .ui-box {
        padding: 12px 16px;
        margin: 0;

        .ui-text {
            margin-top: 0;
        }

        .inline-grid {

            th,
            td {
                border: 1px solid rgba($black, .08);
                padding: 8px 12px;
                font-size: 14px;
            }

            th {
                font-weight: 600;
            }

            td {
                font-weight: 400;
                ;
            }

            button {
                cursor: pointer;
                width: auto;
                margin: 0 8px;
                color: #0060a1;
                font-size: 13px;
                background-color: transparent;
                border: 0;
                padding: 0;
            }

            input {
                max-width: 100%;
                padding: 5px 10px;
                width: 100%;
                background-color: rgb(243, 242, 241);
                border: 0;
                border-radius: 4px;
                outline: none;
                height: auto;
            }

            .ui-form__dropdown {
                .ui-dropdown {
                    width: 100%;
                }

                .ui-dropdown__trigger-button {
                    height: auto;
                    min-height: 26px;
                }

                .ui-box {
                    padding: 3px 5px;
                }
            }
        }

        .k-button-solid-primary {
            border-color: $primary;
            color: $white;
            background-color: #6264a7;
            background-image: linear-gradient(rgba($black, 0), rgba($black, 0.02));

            .ui-icon {
                margin-right: 0.6rem;
            }
        }

        .btn {
            .ui-button {
                color: rgb(17, 16, 15);

                &[tabindex="0"] {
                    border-color: $primary;
                }
            }

            &.active {
                .ui-button {
                    border-color: $primary;
                }
            }
        }

        &.ui-loader__indicator,
        &.ui-loader__svg {
            padding: 0;
        }
    }

    .ui-dialog__footer {
        padding: 12px 16px;
    }
}

.ui-card__header {
    .btn {
        .ui-button {
            color: rgb(17, 16, 15);

            &[tabindex="0"] {
                border-color: $primary;
            }
        }

        &.active {
            .ui-button {
                border-color: $primary;
            }
        }
    }
}

.dbrepo-segment {
    position: relative;
    padding-top: 25px !important;
}

.dbrepo-segment .copy-icon {
    position: absolute;
    top: 0;
    right: 0px;
}

.dbrepo-segment .copied-label {
    position: absolute;
    top: -16px;
    right: 12px;
}

.ui-provider {
    @media screen and (max-width: 767px) {
        padding-left: 2%;
    }

    .ui-popup__content {
        @media screen and (max-width: 767px) {
            max-width: 94% !important;
        }
    }
}

.ui-popup__content__content {
    @media screen and (max-width: 767px) {
        max-width: 94% !important;
    }

    .ui-flex {
        min-width: 0 !important;

        .ui-grid {
            @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start !important;
            }
        }
    }

    .ui-dropdown {
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        &__container {
            max-width: 100%;
        }
    }
}

.ui-pills {
    .ui-pillcontent {
        @media screen and (max-width: 767px) {
            white-space: nowrap;
            max-width: 60px;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.dropdown-width {
    .ui-dropdown {
        max-width: 200px;

        &__container {
            max-width: 100%;
        }
    }
}

img.ddl-img:after {
    content: attr(alt);
    font-size: 16px;
    color: #646464;
    position: absolute;
    z-index: 2;
    top: 4px;
    left: 10px;
    background-color: #e2e2f3;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;
}

img.ddl-img {
    font-size: 1px;
}

.business-hours {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.08);

    td,
    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        border-spacing: 0;
        border-collapse: collapse;
        margin: 0;
        padding: 5px 0;
    }

    tr:nth-child(2n+1) {
        td {
            background-color: rgba(0, 0, 0, 0.02);
        }
    }

    tr:last-child {
        td {
            border-bottom: 0;
        }
    }
}

.time-ddl ul[role="listbox"] {
    width: 150px;
}

.time-ddl-container {
    width: 150px;
    max-width: 150px;
}

.form-checkbox span {
    margin-top: 2px;
}

.aws-notification-type .ui-dropdown__container {
    width: 215px;
}

.aws-destEmail input {
    width: 215px;
}

.aws-applyFilter {
    width: 100px;
}

.ui-tooltip__content {
    z-index: 10003 !important;

    div {
        text-align: center;
        font-size: 13px;
    }
}

.ch-new-billing {
    min-height: 52px;
    justify-content: center;
}

.top-kpi .ui-card .ch-new-billing h6 {
    font-size: 16px;
}

.top-kpi .ui-card .cb-new-billing h2 {
    font-size: 25px;
    margin-top: 8px;
}

.navigation nav .nav-menu-list>.nav-menu.nav-menu-active>a {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
}

.dialog-timesheets .datepicker-timesheet {
    margin-bottom: 14px !important;
}

.datepicker-timesheet input.ui-input__input {
    width: 150px;
}

.dialog-timesheets.k-dialog-md {
    justify-content: flex-start;
    margin-top: 10px;
}



.dialog-timesheets .k-header {
    background-color: #0060a1;
}

.dialog-timesheets .submit-timesheet {
    border-color: #0060a1;
    color: white;
    background-color: #0060a1;
}

.dialog-timesheets .submit-timesheet:active,
.dialog-timesheets .submit-timesheet:hover {
    border-color: #0060a1;
    color: white;
    background-color: #036aae;
}

.timesheet-input input {
    border: none;
    border: solid 1px #ccc;
    border-radius: 7px;
}

.timesheet-filter .filter-by-items {
    text-align: left;
    width: 161px;
    margin-right: 2px;
}

.timesheet-filter .ui-datepicker .ui-input__input {
    padding-right: 0;
    width: 130px;
}

.missing-container {
    align-items: flex-start;
    margin-left: 1px;
}

.dialog-timesheets .ui-form__dropdown {
    margin-bottom: 16px !important;
}

.dialog-timesheets .ui-form__input {
    margin-bottom: 16px !important;
}

.dialog-timesheets .notes-input {
    margin-bottom: 5px !important;
}

.timesheet-filter fieldset {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.timesheets-daily>.k-dialog {
    min-width: 800px !important;
}

.daily-list {
    margin-top: 15px;
    width: 1000px;
}

.daily-list th {
    text-align: left;
}

.daily-list,
.daily-list th,
.daily-list td {
    border: 1px solid #ccc;
    border-collapse: collapse;
}

.daily-list {
    border-radius: 4px;
}

.daily-list .daily-project ul[role="listbox"] {
    width: 185px;
}

.daily-list .daily-project ul[aria-hidden="false"] {
    height: 125px;
}

.daily-list .daily-hours ul[role="listbox"] {
    width: 155px;
}

.daily-list .daily-hours ul[aria-hidden="false"] {
    height: 125px;
}


.timesheets-daily .k-header {
    background-color: #0060a1;
}

.timesheets-daily .submit-timesheet {
    border-color: #0060a1;
    color: white;
    background-color: #0060a1;
}

.timesheets-daily .submit-timesheet:active,
.timesheets-daily .submit-timesheet:hover {
    border-color: #0060a1;
    color: white;
    background-color: #036aae;
}

.timesheets-daily.k-dialog-md {
    justify-content: flex-start;
    margin-top: 70px;
}

.daily-list {
    font-size: 13px;
}

.daily-list th,
.daily-list td {
    padding: 5px 10px;
}


.daily-list .ui-button__content {
    font-weight: normal;
    font-size: 13px;
}

.daily-list .ui-text {
    font-size: 13px;
}

.daily-list .error {
    border: 1px solid #e73939;
    border-radius: 4px;
}

.timesheets-daily .ui-datepicker .ui-input__input {
    padding-right: 0;
    width: 130px;
}

.timesheets-daily .k-dialog-buttongroup {
    padding-right: 0;
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
    color: white !important;
}

.k-pager-numbers .k-link {
    color: gray !important;
}

.k-button-solid-primary {
    border-color: #6264a7 !important;
    color: white !important;
    background-color: #6264a7 !important;
    ;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-pager-numbers .k-link {
    color: wheat !important;
}

.k-pager-numbers .k-link.k-state-selected {
    color: white !important;
    background-color: #6264a7 !important;
    ;
}

.timesheet-IsPS .ui-checkbox {
    display: flex;
}

.timesheet-IsPS .ui-checkbox .ui-checkbox__label {
    padding-top: 1px;
    margin-left: -4px;
}

.timesheet-IsPS .ui-checkbox__indicator {
    margin-left: 12px;
}

.timesheet-IsPS {
    margin-bottom: 16px !important;
}

.weelyDt input {
    color: #000 !important;
    text-align: center;


}

.timesheets-daily .prev-icon,
.timesheets-daily .next-icon {
    cursor: pointer;
}

.timesheets-daily .prev-icon {
    margin-left: -11px;
}

.timesheets-daily .daily-list.weekly-list {
    width: 1050px;
    margin-bottom: 0;
}

.timesheets-daily.timesheets-weekly.k-dialog-md {
    margin-top: 4px;
}

.timesheets-daily.timesheets-weekly .k-dialog-titlebar {
    padding: 7px 16px
}

.timesheets-daily.timesheets-weekly .k-window {
    max-height: 95%;
}

.k-window {
    &-titlebar {
        color: #fff !important;
        background: #6264a7 !important;
    }
}

.k-dialog {
    &-titlebar {
        color: #fff !important;
        background: #6264a7 !important;
    }
}

.k-header {
    color: #fff !important;
    background: #6264a7 !important;
}


.timesheets-weekly .error {
    color: #dc3545 !important;
    border-radius: 4px;
}

.timesheets-weekly .ui-form__input input {
    padding-left: 5px;
    padding-right: 5px;
}

.missing-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.missing-date {
    flex: 0 0 8.33333333%;
    font-family: Consolas, Courier, monospace;
    font-size: 14px;
    // outline: 1px solid;

}

.missing-ts {
    width: 68%;
    // position: absolute;
    // top: 46px;
}

table.daily-list.weekly-list tr:nth-child(odd) {
    border-bottom: 1px solid #777;
}

table.daily-list.weekly-list tr:nth-child(odd) td {
    border-bottom: none;
}

table.daily-list.weekly-list tr:nth-child(even) td {
    border-top: none;
}

table.daily-list.weekly-list {
    border: 1px solid #777;
    border-collapse: collapse;
}

table.daily-list.weekly-list th {
    border: 1px solid #dbdbdb;
    border-top: none;
    border-bottom: none;
    border-collapse: collapse;
}

table.daily-list.weekly-list td {
    border: 1px solid #dbdbdb;
    border-collapse: collapse;
}

table.daily-list.weekly-list td.date-cell {
    border-top: none;
    border-bottom: none;
    border-left: none;
}

table.daily-list.weekly-list td.notes-cell {
    border-right: none;
}

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10012;
    // cursor: pointer;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.d-block {
    display: block;
}

.daily-db .ui-dropdown__selected-items button {
    color: #7a7a7a !important;
    padding: 0 0px 0 8px;
    height: 27px;
    min-height: 0;
}

.daily-db.ui-form__input input.ui-box {
	color: #7a7a7a !important;
	padding: 6px 5px 3px 7px;
}

table.daily-list.weekly-list td .db-table {
	border: none;
}

table.daily-list.weekly-list td .db-table td {
	border-collapse: collapse;
}

.db-table td.cell {
	border: 1px solid #ccc;
	border-collapse: collapse;
	padding-right: 0;
}

.db-table td.cell div.cell-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 80px;
}

.db-table td.cell.wt-db div.cell-content {
	width: 145px;
}

.db-table {
	border-collapse: collapse;
}

table.weekly-list,
table.weekly-list td.weekly-cell {
	border: 1px solid #ccc;
	border-collapse: collapse;
}

.weekly-list .add-icon {
	margin-left: 7px;
	margin-top: 3px;
	margin-bottom: 3px;
	width: 21px;
	min-width: 0;
	min-height: 0;
	height: 20px;
}

table.daily-list.weekly-list td .user-table {
	border: none;
}

table.daily-list.weekly-list td .user-table td {
	border-collapse: collapse;
}

.user-table td.cell {
	border: 1px solid #ccc;
	border-collapse: collapse;
	// padding-right: 0;
}

// .user-table td.cell div.cell-content {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: 85px;
// }

.user-table {
	border-collapse: collapse;
}

.weekly-list .dt-h,
.weekly-list .dt-db,
.weekly-list .dt-u {
	width: 100px;
	max-width: 67px;
}

.weekly-list .wt-h,
.weekly-list .wt-db,
.weekly-list .wt-u {
	width: 70px;
	max-width: 90px;
}

.weekly-list .hr-h,
.weekly-list .hr-db,
.weekly-list .hr-u {
	width: 100px;
	max-width: 90px;
}

.weekly-list .pr-h,
.weekly-list .pr-db,
.weekly-list .pr-u {
	width: 100px;
	max-width: 100px;
}

.weekly-list .vr-h,
.weekly-list .vr-db,
.weekly-list .vr-u {
	width: 80px;
	max-width: 77px;
}

.weekly-list .ps-h,
.weekly-list .ps-db,
.weekly-list .ps-u {
	width: 70px;
	max-width: 57px;
}

.weekly-list .jr-h,
.weekly-list .jr-db,
.weekly-list .jr-u {
	width: 70px;
	max-width: 57px;
}

.weekly-list .no-h,
.weekly-list .no-db,
.weekly-list .no-u {
	width: 70px;
	max-width: 57px;
}

.weekly-wrap {
	max-height: 500px;
	overflow-y: scroll;
	margin-bottom: 0 !important;
	min-height: 158px;
	overflow-x: hidden;
}

.daily-list.weekly-list {
	margin-top: 0;
}

.datepicker-timesheet .ui-input__input {
	padding-right: 12px;
}

.timesheets-daily .daily-wrap {
	max-height: 600px;
	margin-bottom: 10px !important;
}

.daily-cell .ui-input__input {
	padding-right: 10px;
}

.daily-list.weekly-list .daily-workType ul[role="listbox"] {
	width: inherit;
}

.daily-list.weekly-list .daily-hours ul[role="listbox"] {
	width: inherit;
}

.daily-list.weekly-list .daily-project ul[role="listbox"] {
	width: inherit;
}

.user-table .cell.wt-u,
.db-table .cell.wt-db {
	border-left: none;
}

.user-table .cell.no-u,
.db-table .cell.no-db {
	border-right: none;
}

.user-table button.ui-dropdown__trigger-button.ui-button {
	height: 25px;
	min-height: auto;
	padding: 0 8px;
}

.user-table input.ui-input__input {
	height: 25px;
}

.daily-list.weekly-list th,
.daily-list.weekly-list td {
	padding: 4px 7px;
}

.timesheets-daily .ui-dropdown__item__header {
	font-size: 12px;
}

.ui-dropdown__item__main {
	cursor: default;
}

.fy-ddl {
	width: 240px;
}

.filter-btn {
	box-shadow: none;
	border:  1px solid rgba(0, 0, 0, .09);
}

.download-icon button {
	box-shadow: none;
	border:  1px solid rgba(0, 0, 0, .08);
}

.fy-ddl .ui-dropdown__container {
		border: 1px solid rgba(0, 0, 0, .05);
    height: 32px;
}

.dashboard-selectbox .ui-dropdown__container{
	border: 1px solid rgba(0, 0, 0, .05);
	height: 32px;
}

.dashboard-selectbox .ui-dropdown__container:focus-within {
	border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.fy-ddl .ui-dropdown__container:focus-within {
	border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.fy-ddl ul.ui-dropdown__items-list {
	transform: translate(0.2px, 31.2px) !important;
}

.addcardcontainer .ui-segment {
	box-shadow: rgba(34, 36, 38, 0.04) 1px 1px 2px 1px;
}

.border-none {
	border: none !important;;
	box-shadow: none !important;
}

.dashboard-body .rev-chart, .dashboard-body .rev-chart:hover {
	border: 1px solid rgba(0, 0, 0, .08);
}


.daily-list .daily-version ul[aria-hidden=false] {
    height: 125px;
}

.viewAll-dialog .k-window.k-dialog {
    width: 100%;
    height: 100%;
}

.revenue-chart-wrapper {
    // width: 100px;
    overflow-y: hidden;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
}

.viewAll-dialog .k-window-titlebar {
    padding-left: 15px;
    padding-right: 11px;
}

.clear-back {
    rotate: -20deg;
    position: absolute;
    left: 3px;
}

.clear-container {
    display: flex;
    position: relative;
    margin-bottom: 8px;
    width:65px;
    cursor: pointer;
    color: #2727a5;
}

.allFull-container {
    background-color: #fff;
    margin: 20px;
    
}

.customer-chart-tooltip {
    display: flex;
    flex-direction: column;
    font-size: 10px;
}

.ui-tooltip__content {
    word-break: break-word;
}

.k-animation-container {
    z-index: 10004  !important;
}